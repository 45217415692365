export const Logo = () => (
    <svg width="105" height="20" viewBox="0 0 105 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(.clip0_274_1431)">
            <path d="M8.00977 19.8909C6.818 19.8909 5.84796 19.6588 5.12736 19.181C4.69777 18.9079 4.22661 18.4165 3.71387 17.7202V19.5086H0V0.218506H3.78316V7.0854C4.26818 6.41646 4.79477 5.91134 5.36294 5.57004C6.04197 5.13318 6.91501 4.91475 7.96819 4.91475C9.88056 4.91475 11.3633 5.59735 12.4442 6.94888C13.5251 8.30042 14.0656 10.0479 14.0656 12.1912C14.0656 14.4165 13.539 16.2458 12.472 17.7066C11.3911 19.1537 9.90827 19.8909 8.00977 19.8909ZM10.0746 12.5735C10.0746 11.5632 9.93599 10.7168 9.67269 10.0615C9.17382 8.80554 8.23149 8.17755 6.87343 8.17755C5.50152 8.17755 4.55919 8.79189 4.04646 10.0206C3.78316 10.6758 3.64458 11.5223 3.64458 12.5598C3.64458 13.7885 3.92174 14.7987 4.46219 15.6042C5.0165 16.4096 5.84796 16.8055 6.97044 16.8055C7.94048 16.8055 8.70265 16.4233 9.2431 15.6451C9.81127 14.867 10.0746 13.8431 10.0746 12.5735Z" fill="white" />
            <path d="M29.1705 15.3174C29.0735 16.1639 28.6301 17.0239 27.8263 17.8977C26.5791 19.2901 24.8469 19.9864 22.6158 19.9864C20.7728 19.9864 19.1514 19.3994 17.7379 18.2253C16.3244 17.0512 15.6177 15.1536 15.6177 12.5188C15.6177 10.0478 16.2551 8.15021 17.53 6.83963C18.805 5.5154 20.454 4.86011 22.4773 4.86011C23.6829 4.86011 24.7638 5.07854 25.7338 5.52905C26.7039 5.97956 27.4937 6.67581 28.1312 7.63144C28.6994 8.47785 29.0597 9.46079 29.2398 10.5802C29.3368 11.2355 29.3784 12.1775 29.3645 13.4062H19.4424C19.4978 14.8396 19.9551 15.8362 20.8143 16.4232C21.3409 16.7782 21.9645 16.9557 22.699 16.9557C23.475 16.9557 24.1125 16.7372 24.5975 16.3004C24.8608 16.0683 25.0964 15.7407 25.3042 15.3174H29.1705ZM25.4289 10.9352C25.3735 9.95226 25.0686 9.2014 24.5143 8.68263C23.9739 8.17751 23.2949 7.91813 22.4911 7.91813C21.6181 7.91813 20.939 8.19117 20.454 8.72359C19.969 9.26966 19.6641 9.99321 19.5394 10.9215H25.4289V10.9352Z" fill="white" />
            <path d="M38.7324 7.98645C37.4436 7.98645 36.5706 8.51887 36.0855 9.59737C35.8361 10.1707 35.7114 10.8943 35.7114 11.768V19.495H31.9282V5.25607H35.5867V7.34481C36.0717 6.60761 36.529 6.08884 36.9724 5.76119C37.7485 5.18781 38.7462 4.90112 39.938 4.90112C41.4346 4.90112 42.668 5.28338 43.6103 6.06153C44.5665 6.83969 45.0376 8.12297 45.0376 9.91136V19.5223H41.1575V10.826C41.1575 10.0752 41.0605 9.5018 40.8526 9.09225C40.4923 8.35505 39.7856 7.98645 38.7324 7.98645Z" fill="white" />
            <path d="M60.9601 15.3174C60.8631 16.1639 60.4196 17.0239 59.6159 17.8977C58.3687 19.2901 56.6365 19.9864 54.4054 19.9864C52.5623 19.9864 50.9409 19.3994 49.5275 18.2253C48.114 17.0512 47.4072 15.1536 47.4072 12.5188C47.4072 10.0478 48.0447 8.15021 49.3196 6.83963C50.5945 5.5154 52.2436 4.86011 54.2668 4.86011C55.4724 4.86011 56.5533 5.07854 57.5234 5.52905C58.4934 5.97956 59.2833 6.67581 59.9208 7.63144C60.4889 8.47785 60.8492 9.46079 61.0294 10.5802C61.1264 11.2355 61.1679 12.1775 61.1541 13.4062H51.232C51.2874 14.8396 51.7447 15.8362 52.6039 16.4232C53.1305 16.7782 53.7541 16.9557 54.4885 16.9557C55.2646 16.9557 55.902 16.7372 56.387 16.3004C56.6503 16.0683 56.8859 15.7407 57.0938 15.3174H60.9601ZM57.2046 10.9352C57.1492 9.95226 56.8443 9.2014 56.29 8.68263C55.7496 8.17751 55.0705 7.91813 54.2668 7.91813C53.3938 7.91813 52.7147 8.19117 52.2297 8.72359C51.7447 9.26966 51.4398 9.99321 51.3151 10.9215H57.2046V10.9352Z" fill="white" />
            <path d="M70.4941 0.0819113V3.12628C70.2863 3.09898 69.926 3.08532 69.4271 3.07167C68.9282 3.05802 68.5818 3.16724 68.3878 3.39932C68.1938 3.6314 68.0968 3.89079 68.0968 4.16382V5.36519H70.5773V8H68.0968V19.5085H64.3136V7.98635H62.1934V5.35154H64.272V4.43686C64.272 2.90785 64.5353 1.85666 65.0619 1.28328C65.6162 0.423208 66.9327 0 69.0391 0C69.2747 0 69.4964 0 69.6904 0.0136519C69.8844 0.0273038 70.1477 0.0546075 70.4941 0.0819113Z" fill="white" />
            <path d="M76.6609 0.0820312V3.5223H72.8223V0.0820312H76.6609ZM76.6609 5.22879V19.5087H72.8223V5.22879H76.6609Z" fill="white" />
            <path d="M103.171 7.03062C104.39 8.54597 105 10.3344 105 12.3822C105 14.4845 104.39 16.2729 103.171 17.761C101.951 19.249 100.094 19.9999 97.6 19.9999C95.1056 19.9999 93.2486 19.249 92.0292 17.761C90.8097 16.2729 90.2 14.4845 90.2 12.3822C90.2 10.3207 90.8097 8.53232 92.0292 7.03062C93.2486 5.51526 95.1056 4.7644 97.6 4.7644C100.081 4.7644 101.951 5.52891 103.171 7.03062ZM97.5861 7.93164C96.4775 7.93164 95.6183 8.31389 95.0224 9.09205C94.4266 9.87021 94.1217 10.9624 94.1217 12.3958C94.1217 13.8293 94.4266 14.9351 95.0224 15.7132C95.6183 16.4914 96.4775 16.8736 97.5861 16.8736C98.6947 16.8736 99.5401 16.4914 100.136 15.7132C100.732 14.9351 101.023 13.8293 101.023 12.3958C101.023 10.9624 100.732 9.85655 100.136 9.09205C99.5401 8.31389 98.6947 7.93164 97.5861 7.93164Z" fill="white" />
            <path d="M82.2733 12.819H86.0149V8.60062H90.3524V5.2286H86.0149V0.955566H82.2733V5.2286H77.9497V8.60062H82.2733V12.819Z" fill="white" />
        </g>
        <defs>
            <clipPath className="clip0_274_1431">
                <rect width="105" height="20" fill="white" />
            </clipPath>
        </defs>
    </svg>
);