import React from 'react';
import {
    ConceptsContainer,
    ConceptWrapper,
    ConceptContainer,
    ConceptIconContainer,
    ConceptDescription,
    ConceptIcon,
    ConceptImage
} from './styles';

import conceptVector from '../assets/images/concept-vector.svg';
import location from '../assets/images/location.svg';
import couple from '../assets/images/couple.svg';
import tenis from '../assets/images/tenis.svg';
import conceptImage from '../assets/images/concept-image.svg';

const Concept = () => {
    const concepts = [
        {
            description: 'You set a monthly budget for your employees on Benefito',
            icon: couple
        }, {
            description: 'We make suggestions based on interests and location',
            icon: location
        }, {
            description: 'Your employees gain access to huge variety of perks and benefits',
            icon: tenis
        }
    ]
    return (
        <ConceptsContainer id="how-it-works">
            <ConceptWrapper>
                {concepts.map((concept, index) => (
                    <ConceptContainer key={index}>
                        <ConceptIconContainer>
                            <img src={conceptVector} />
                            <ConceptIcon src={concept.icon} />
                        </ConceptIconContainer>
                        <ConceptDescription>
                            {concept.description}
                        </ConceptDescription>
                    </ConceptContainer>
                ))}
            </ConceptWrapper>
        </ConceptsContainer>
    )
}

export default Concept;