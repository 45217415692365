import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import Concept from './Concept';
import Content from './Content';
import Footer from './Footer';

const Layout = () => {
    return (
        <>
            <Header />
            <Concept />
            <Content />
            <Footer />
        </>
    )
}

export default Layout;