import styled from 'styled-components';

export const HeaderContainer = styled.div`
    background: #5C42FF;
    height: calc(${({ height }) => height}px - 56px);
    @media screen and (min-width: 767px) {
        height: calc(${({ height }) => height}px - 68.5px);
    }
`;

export const HeaderWrapper = styled.div`
    width: 95%;
    margin: 0 auto;
    height: 100%;
    @media screen and (min-width: 601px) {
        width: 90%;
    }
    @media screen and (min-width: 1200px) {
        width: 75%;
    }
`;

export const Image = styled.img.attrs((props) => ({
    src: props.src || ''
}))`
    max-width: 700px;
`

export const MobileBag = styled.img.attrs((props) => ({
    src: props.src || ''
}))`
    width: 80%;
    margin: 0 auto;
    margin-top: -100px;

    @media screen and (min-width: 601px) {
        width: 50%;
    }
`

export const Text = styled.h1`
    color: #fff;
    letter-spacing: 0em;
    margin-top: 0;
    max-width: 360px;
    text-align: center;
    font-weight: 700;
    font-size: 32.0585px;
    line-height: 37px;
    margin: 0 auto;
    margin-top: -50px;
    @media screen and (min-width: 601px) {
        font-size: 62px;
        line-height: 72px;
        text-align: center;
        margin: 0 auto;
        max-width: 570px;
    }
    @media screen and (min-width: 900px) {
        text-align: left;
        margin: 0;
        font-size: 48px;
        line-height: 56px;
        max-width: 600px;
    }
`;

export const ContentContainer = styled.div`
    padding: 56px 0;
    // background: #00FFDB;
    background: #fbfafa;
`;

export const ContentWrapper = styled.div`
    width: 90%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (min-width: 601px) {
        width: 80%;
    }
`;

export const ContentTitle = styled.h1`
    font-style: normal;
    font-weight: 700;
    margin-top: 0;
    font-size: 32px;
    line-height: 37px;
    text-align: center;
    @media screen and (min-width: 601px) {
        font-size: 61.928px;
        line-height: 72px;
    }
`;

export const FormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Form = styled.div`
    display: flex;
    align-items: stretch;
    background: #fff;
    border-radius: 12px;
    padding: 0
    border: 2px solid #000;
    -webkit-box-shadow: 4px 4px 0px 4px #000000; 
    box-shadow: 4px 4px 0px 1px #ddd5cf;
    margin-top: 110px;
    margin-bottom: 10px;
    width: 100%;
    @media screen and (min-width: 601px) {
        padding: 10px 10px 10px 30px;
        width: auto;
    }
`;

export const LeftSide = styled.div`
    padding: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (min-width: 601px) {
        width: 395px;
        max-width: 395px;
        padding: 40px 37px 40px 0;
    }
`;

export const RightSide = styled.div`
    // background: #F7F5FB;
    background: #f7f1ec;
    border-radius: 12px;
    padding: 24px;
    position: relative;
    display: flex;
    align-items: stretch;
    width: 300px;
    max-width: 355px;
`;

export const Megaphone = styled.img.attrs((props) => ({
    src: props.src
}))`
    max-width: 300px;
    position: absolute;
    top: -60px;
    left: -10px;
`;

export const MobileMegaphone = styled.img.attrs((props) => ({
    src: props.src
}))`
    max-width: 150px;
    position: absolute;
    bottom: -50%;
    right: -10px;
`;

export const InputContainer = styled.div`
    background: #fff;
    display: flex;
    padding: 0 18px;
    @media screen and (min-width: 601px) {
        padding: 0;
    }
`;

export const Input = styled.input.attrs((props) => ({
    type: 'email',
    placeholder: props.placeholder || ''
}))`
    border-radius: 25px;
    outline: none;
    border: 1px solid #B3B3B3;
    padding: 12px 18px;
    font-family: mont-regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    width: 100%;
    margin-top: 16px;
    @media screen and (min-width: 601px) {
        margin-top: 24px;
    }
`;

export const Inputs = styled.div`
    z-index: 101;
`;

export const Textarea = styled.textarea.attrs((props) => ({
    placeholder: props.placeholder || ''
}))`
    flex: 1;
    margin-top: 16px;
    border-radius: 12px;
    padding: 12px 18px;
    font-family: mont-regular;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    border: 1px solid #B3B3B3;
    height: 150px;
`;

export const ButtonContainer = styled.div`
    background: #fff;
    z-index: 101;
    padding: 12px 18px 18px 18px;
    border-radius: 12px;
    @media screen and (min-width: 601px) {
        padding: 0;
        margin-top: 16px;
    }
`;

export const FormTitle = styled.h1`
    color: #000;
    font-weight: 700;
    font-size: 40px;
    line-height: 51px;
    margin: 0;
    max-width: 370px;
`;

export const MobileFormTitle = styled.div`
    // background: #3C30C8;
    background: #f7f1ec;
    border-radius: 12px 12px 0 0;
    position: relative;
`;

export const MobileFormText = styled.h1`
    font-weight: 700;
    font-size: 29.1405px;
    line-height: 37px;
    padding: 36px 24px;
    color: #000;
    z-index: 9999;
    position: relative;
    max-width: 80%;
`;

export const FormSubtitle = styled.p`
    color: #908F8F;
    padding: 17px;
    font-family: mont-regular;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    background: #fff;
    z-index: 9999;
    margin: 0;
    @media screen and (min-width: 601px) {
        padding: 17px 0 0 0;
    }
`;

export const Benefit = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 16px;
`;

export const BenefitIcon = styled.div`
    display: flex;
    max-width: 120px;
    max-height: 120px;
    width: 100%;
    height: 100%;
    > img {
        width: 100%;
    }
`;

export const BenefitTitle = styled.span`
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    @media screen and (min-width: 601px) {
        font-size: 23px;
        line-height: 30px;
    }
`;

export const MessageContainer = styled.div`
    padding: 0 18px 18px 18px;
    @media screen and (min-width: 601px) {
        margin-top: 8px;
        padding: 0;
    }
`;

export const FooterContainer = styled.div`
    padding: 16px 0;
    background: #5C42FF;
`;

export const FooterWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
`;

export const Rights = styled.div`
    flex: 1;
    color:  #fff;
`;

export const Socials = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
`;

export const SocialLink = styled.a.attrs((props) => ({
    href: props.href || '#',
    target: '_blank'
}))`
    text-decoration: none;
    // color: ${(props) => props.location ==='header' ? '#fff' : '#3C30C8'};
    color: #fff;
`;

export const ConceptsContainer = styled.div`
    background: #5C42FF;
    overflow-x: scroll;
    ::-webkit-scrollbar {
        display: none;
    }
`;

export const ConceptWrapper = styled.div`
    margin: 0 auto;
    height: 100%;
    padding-bottom: 250px;
    display: flex;

    max-width: none;
    width: 810px;
    flex-wrap: nowrap;
    padding-left: calc((100% - 270px) / 2);
    padding-right: calc((100% - 270px) / 2);

    @media screen and (min-width: 769px) {
        width: 90%;
        overflow: initial; 
        padding-left: 0;
        padding-right: 0;
    }
    @media screen and (min-width: 1200px) {
        width: 75%;
    }
`;

export const ConceptContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0 10px;
    @media screen and (min-width: 769px) {
        width: auto;
        flex: 1;
        padding: 0;
    }
`;

export const ConceptIconContainer = styled.div`
    position: relative;
`;

export const ConceptIcon = styled.img.attrs((props) => ({
    src: props.src || ""
}))`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
`;

export const ConceptDescription = styled.p`
    text-align: center;
    max-width: 260px;
    font-family: mont-regular;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #fff;
`;

export const ConceptImageContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    position: relative;
`;

export const ConceptImage = styled.img.attrs((props) => ({

}))`
    // position: absolute;
    // bottom: -50%;
    // left: 50%;
    // transform: translate(-50%, -37%);
    max-width: 170px;
    z-index: 9999;
    position: relative;
    margin: -210px auto 60px auto;
`;