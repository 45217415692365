import Navigation from './components/Navigation';
import Layout from './components/Layout';
import './assets/fonts/Mont-Regular.woff2';
import './assets/fonts/Mont-Bold.woff2';

function App() {
  return (
    <>
      <Navigation />
      <Layout />
    </>
  );
}

export default App;
