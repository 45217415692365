import React, { useEffect, useRef, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
    HeaderContainer,
    Image,
    Text,
    HeaderWrapper,
    MobileBag,
} from './styles';

import headerBag from '../assets/images/header-bag.png';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';

const Header = () => {
    const windowHeight = window.innerHeight;
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(min-width:601px) and (max-width: 899px)');
    const isDesktop = useMediaQuery('(min-width: 900px)');

    const handleClick = () => {
        const element = document.getElementById('contact-form');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    return (
        <HeaderContainer height={windowHeight}>
            <HeaderWrapper>
                <Grid container sx={{
                    height: '100%'
                }}>
                    <Grid item xs={12} md={6} sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%'
                    }}>
                        {(isMobile || isTablet) &&
                            <MobileBag src={headerBag} />
                        }
                        <Text>
                            Employee wellness is the key to workplace happiness
                        </Text>
                        <Button
                            id="header-try-tool"
                            variant="text"
                            onClick={handleClick}
                            sx={{
                                background: '#ddac6d',
                                color: '#000',
                                borderRadius: '27px',
                                padding: '11px 17px',
                                textTransform: 'none',
                                fontSize: '20px',
                                fontWeight: 400,
                                lineHeight: '28px',
                                letterSpacing: '0em',
                                width: isMobile ? '100%' : 'fit-content',
                                fontFamily: 'mont-regular',
                                margin: (isMobile || isTablet) ? '32px auto 0 auto' : '24px 0 0 0',
                                ":hover": {
                                    background: 'rgba(0,255,219, .9)'
                                },
                                "@media screen and (max-width: 400px)": {
                                    fontSize: '16px'
                                }
                            }}>Book a&nbsp;<b>free</b>&nbsp;demo</Button>
                    </Grid>
                    {isDesktop &&
                        <Grid item xs={12} md={6} sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <Image src={headerBag} />
                        </Grid>
                    }
                </Grid>
            </HeaderWrapper>
        </HeaderContainer>
    )
}

export default Header;