import React from 'react';
import {
    FooterContainer,
    FooterWrapper,
    Rights,
    Socials,
    SocialLink
} from './styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
    return (
        <FooterContainer>
            <FooterWrapper>
                <Rights>
                    &copy;2023 Benefito
                </Rights>
                <Socials>
                    <SocialLink id="footer-social-linkedin" href="https://www.linkedin.com/company/benefito-platform">
                        <FontAwesomeIcon icon={faLinkedin} size='lg' />
                    </SocialLink>
                    <SocialLink id="footer-social-facebook" href="https://www.facebook.com/benefitobg">
                        <FontAwesomeIcon icon={faFacebook} size='lg' />
                    </SocialLink>
                </Socials>
            </FooterWrapper>
        </FooterContainer>
    )
}

export default Footer;