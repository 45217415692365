import React, { useState } from 'react';
import { Button, FormControl, Select, MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import emailjs from '@emailjs/browser';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThreeDots } from 'react-loader-spinner';
import {
    ContentContainer,
    ContentWrapper,
    FormWrapper,
    Form,
    LeftSide,
    RightSide,
    Megaphone,
    InputContainer,
    Input,
    Inputs,
    Textarea,
    FormTitle,
    FormSubtitle,
    ContentTitle,
    Benefit,
    BenefitTitle,
    BenefitIcon,
    MobileFormTitle,
    MobileFormText,
    MobileMegaphone,
    ButtonContainer,
    MessageContainer,
    ConceptImageContainer,
    ConceptImage
} from './styles';

import megaphone from '../assets/images/megaphone.png';
import financial from '../assets/images/financial.png';
import physical from '../assets/images/physical.png';
import mental from '../assets/images/mental.png';
import emotional from '../assets/images/emotional.png';
import social from '../assets/images/social.png';
import intellectual from '../assets/images/intellectual.png';
import conceptImage from '../assets/images/concept-image.svg';

const benefits = [
    {
        icon: physical,
        name: 'Physical'
    },
    {
        icon: mental,
        name: 'Mental'
    },
    {
        icon: emotional,
        name: 'Emotional'
    },
    {
        icon: social,
        name: 'Social'
    },
    {
        icon: intellectual,
        name: 'Intellectual'
    }
    ,
    {
        icon: financial,
        name: 'Financial'
    }
];

const roles = [
    {
        name: 'HR',
        label: 'hr'
    },
    {
        name: 'Business owner',
        label: 'business-owner'
    },
    {
        name: 'Employee',
        label: 'employee'
    },
    {
        name: 'Other',
        label: 'other'
    }
];

const reasons = [
    {
        name: 'Fund our ideas :)',
        label: 'funding'
    },
    {
        name: 'Become our business partner',
        label: 'partnership'
    },
    {
        name: 'Become our early adopter',
        label: 'early-adopter'
    },
    {
        name: 'Become our benefit provider',
        label: 'benefit-provider'
    },
    {
        name: 'Understand more about us',
        label: 'more-about-us'
    },
    {
        name: 'Other',
        label: 'other'
    }
]
const Content = () => {
    const [isValid, setIsValid] = useState(false);
    const [resMessage, setResMessage] = useState(null);

    const [submitClicked, setSubmitClicked] = useState(false);

    const serviceId = 'service_ck1paso';
    const templateId = 'template_429z4n8';
    const publicKey = 'TkWJXCvEoa9Vd7eye';
    const isMobile = useMediaQuery('(max-width:600px)');

    const [formInputs, setFormInputs] = useState({
        email: '',
        role: '',
        reason: '',
        message: '',
    });

    const onFieldChange = (event, field) => {
        setFormInputs(({ ...formInputs, [field]: event.target.value }));
    }

    const handleEmailChange = (event) => {
        onFieldChange(event, 'email');
        if (isValidEmail(event.target.value)) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }

    const handleRoleChange = (event) => {
        onFieldChange(event, 'role');
    }
    const handleReasonChange = (event) => {
        onFieldChange(event, 'reason');
    }

    const handleMessageChange = (event) => {
        onFieldChange(event, 'message')
    }

    const isValidEmail = (email) => {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/.test(email);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setSubmitClicked(true);
        emailjs.send(`${serviceId}`, `${templateId}`, {
            user_email: formInputs.email,
            user_role: formInputs.role,
            user_reason: formInputs.reason,
            user_message: formInputs.message
        }, `${publicKey}`)
            .then((result) => {
                setResMessage('Welcome to our community!')
                setSubmitClicked(false);
            }, (error) => {
                setResMessage('Something went wrong! Better contact Aleksa for details :)')
                setSubmitClicked(false);
            });
    }

    return (
        <ContentContainer>
            <ConceptImageContainer>
                <ConceptImage src={conceptImage} />
            </ConceptImageContainer>
            <ContentWrapper id="our-benefits">
                <FormWrapper>
                    <ContentTitle>Our benefits categories</ContentTitle>
                    <Grid container>
                        {benefits.map((benefit, i) => (
                            <Grid item xs={6} md={3} key={i}>
                                <Benefit>
                                    <BenefitIcon>
                                        <img src={benefit.icon} alt={benefit.name} />
                                    </BenefitIcon>
                                    <BenefitTitle>
                                        {benefit.name}
                                    </BenefitTitle>
                                </Benefit>
                            </Grid>
                        ))}
                    </Grid>
                    <Form id='contact-form book-a-demo'>
                        <LeftSide>
                            {!isMobile && <FormTitle>Looks interesting?</FormTitle>}
                            {isMobile &&
                                <MobileFormTitle>
                                    <MobileFormText>
                                        Looks interesting? Be the first to try our services.
                                    </MobileFormText>
                                    <MobileMegaphone src={megaphone} />
                                </MobileFormTitle>
                            }
                            {!isMobile && <FormSubtitle>Be the first to try our services.</FormSubtitle>}
                            <Inputs>
                                <InputContainer>
                                    <Input placeholder='Enter your email address' onChange={handleEmailChange} />
                                </InputContainer>
                                <InputContainer>
                                    <FormControl fullWidth>
                                        <Select
                                            displayEmpty
                                            value={formInputs.role}
                                            renderValue={(role) => {
                                                if (role.length === 0) {
                                                    return <>Select your role</>;
                                                }

                                                return role;
                                            }}
                                            sx={{
                                                borderRadius: '25px',
                                                marginTop: '16px',
                                                fontFamily: 'mont-regular',
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                lineHeight: '21px'
                                            }}
                                            onChange={handleRoleChange}
                                        >
                                            {roles.map((role, index) => (
                                                <MenuItem value={role.name} key={index}>{role.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </InputContainer>
                                <InputContainer>
                                    <FormControl fullWidth>
                                        <Select
                                            displayEmpty
                                            value={formInputs.reason}
                                            renderValue={(reason) => {
                                                if (reason.length === 0) {
                                                    return <>You're looking to</>;
                                                }

                                                return reason;
                                            }}
                                            sx={{
                                                borderRadius: '25px',
                                                marginTop: '16px',
                                                fontFamily: 'mont-regular',
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                lineHeight: '21px'
                                            }}
                                            onChange={handleReasonChange}
                                        >
                                            {reasons.map((reason, index) => (
                                                <MenuItem value={reason.name} key={index}>{reason.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </InputContainer>
                                <InputContainer>
                                    <Textarea placeholder='You are contacting us, because..' onChange={handleMessageChange} />
                                </InputContainer>
                            </Inputs>
                            <ButtonContainer>
                                <Button variant="text"
                                    disabled={!isValid}
                                    sx={{
                                        background: '#4CDFCA',
                                        color: '#200946',
                                        borderRadius: '25px',
                                        padding: '12px 18px',
                                        textTransform: 'none',
                                        fontSize: '20px',
                                        fontWeight: 400,
                                        lineHeight: '28px',
                                        letterSpacing: '0em',
                                        marginTop: '0',
                                        textAlign: 'center',
                                        fontFamily: 'mont-regular',
                                        width: '100%',
                                        height: '45px',
                                        ":hover": {
                                            background: 'rgba(0,255,219, .9)'
                                        },
                                        ":disabled": {
                                            background: '#ccc'
                                        }
                                    }}
                                    onClick={handleSubmit}>{submitClicked ? <ThreeDots
                                        height="30"
                                        width="30"
                                        radius="9"
                                        color="#3C30C8"
                                        ariaLabel="three-dots-loading"
                                        wrapperStyle={{}}
                                        wrapperClassName=""
                                        visible={true}
                                    /> : 'Subscribe Now'}</Button>
                            </ButtonContainer>
                            {resMessage && <MessageContainer>{resMessage}</MessageContainer>}
                        </LeftSide>
                        {!isMobile &&
                            <RightSide>
                                <Megaphone src={megaphone} />
                            </RightSide>
                        }
                    </Form>
                </FormWrapper>
            </ContentWrapper>
        </ContentContainer>
    )
}

export default Content;