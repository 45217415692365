import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Logo } from './Logo';
import {
    Socials,
    SocialLink
} from './styles';
import { faLinkedin, faFacebook } from "@fortawesome/free-brands-svg-icons";
import styled from 'styled-components';

const Link = styled.a.attrs((props) => ({
    href: props.href || "#"
}))`
    text-decoration: none;
    padding: 0 12px;
    color: #fff;
`;

const pages = [
    {
        href: '#how-it-works',
        name: 'How it works'
    },
    {
        href: '#our-benefits',
        name: 'Our benefits'
    }
];

function Navigation() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleClick = () => {
        const element = document.getElementById('contact-form');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    return (
        <AppBar position="static" sx={{
            background: '#5C42FF',
        }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* <Box sx={{ display: { xs: 'flex', md: 'none' }, alignItems: 'center' }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                                marginRight: '16px'
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}
                    <Box>
                        <Logo />
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, marginLeft: '57px' }}>
                        {pages.map((page, index) => (
                            <Link
                                href={page.href}
                                key={index}
                                onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block', fontFamily: 'mont-regular', }}
                            >
                                {page.name}
                            </Link>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Socials>
                            <SocialLink
                                id="header-social-linkedin"
                                href="https://www.linkedin.com/company/benefito-platform"
                                location="header">
                                <FontAwesomeIcon icon={faLinkedin} size='lg' />
                            </SocialLink>
                            <SocialLink
                                id="header-social-facebook"
                                href="https://www.facebook.com/benefitobg"
                                location="header">
                                <FontAwesomeIcon icon={faFacebook} size='lg' />
                            </SocialLink>
                        </Socials>
                        {/* <Button
                            id="header-free-demo"
                            variant="text"
                            onClick={handleClick}
                            sx={{
                                background: '#00FFDB',
                                color: '#000',
                                borderRadius: '27px',
                                padding: '5px 16px',
                                textTransform: 'none',
                                fontFamily: 'mont-regular',
                                ":hover": {
                                    background: 'rgba(0,255,219, .9)'
                                }
                            }}>Request a free demo</Button> */}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default Navigation;